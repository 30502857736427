import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDay,
  faStar,
  faUserAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

const ModalReview = ({ setModal, repair, onClick = () => { } }) => {

  const [starSelected, setStarSelected] = useState(0);
  const [starSelected2, setStarSelected2] = useState(0);
  const [starSelected3, setStarSelected3] = useState(0);
  const [starSelected4, setStarSelected4] = useState(0);
  return (
    <div className="fixed top-0 bottom-0 right-0 left-0">
      <div
        className="bg-black opacity-70 w-full h-full z-10"
        onClick={() => setModal(false)}
      ></div>
      <div className="absolute top-0 bottom-0 right-0 left-0 container max-w-2xl sm:mx-auto px-2 sm:px-0 py-10 flex flex-col gap-y-5 z-20 overflow-y-auto">
        <div className="flex justify-end items-center">
          <div
            className="flex items-center  gap-x-3 cursor-pointer"
            onClick={() => setModal(false)}
          >
            <FontAwesomeIcon
              icon={faTimesCircle}
              className="text-xl sm:text-2xl text-gray bg-[#f9fafb] rounded-full"
            />
            <div className="hidden sm:block text-2xl text-gray cursor-pointer">Close</div>
          </div>
        </div>
        <div className="bg-white rounded-lg mb-9 divide-y-[12px] divide-[#f9fafb]">
          {/* <div className="flex flex-col gap-y-5 px-5 sm:px-12 py-6">
                <div className="flex items-baseline gap-x-3">
                  <div className="text-2xl sm:text-3xl">ประเภท</div>
                  <div className="text-2xl sm:text-3xl px-4 rounded-lg bg-gray-light">
                    {repair.type}
                  </div>
                  <span className="text-xl sm:text-2xl">
                    เลขที่แจ้งซ่อม : {repair.number_repair}
                  </span>
                </div>
                <div className="flex items-center gap-x-3">
                  <FontAwesomeIcon
                    icon={faCalendarDay}
                    className="text-xl sm:text-2xl text-gray"
                  />
                  <div className="sm:text-2xl">
                    <span className="text-gray">วันเวลาที่รับบริการ :</span>{" "}
                    {repair.date}
                  </div>
                </div>
              </div>
              <div className="flex items-center sm:text-xl gap-x-3 px-5 sm:px-12 py-6">
                <FontAwesomeIcon
                  icon={faUserAlt}
                  className="text-xl sm:text-2xl text-gray"
                />
                <span className="text-gray">ทีมงาน :</span> อารียาแฟมมิลี่ 025
              </div> */}
          <div className="px-5 sm:px-12 py-6">
            <div className="flex flex-col divide-y divide-[#cfcfcf]">
              <div className="pb-4">
                <span className="text-2xl">การตรงต่อเวลา</span>
                <div className="flex gap-x-3">
                  <div className="text-xl text-gray">ปรับปรุง</div>
                  {[1, 2, 3, 4].map((item, index) => {
                    return item <= starSelected ? (
                      <div className="cursor-pointer">
                        <FontAwesomeIcon
                          icon={faStar}
                          className="text-2xl text-yellow"
                          onClick={() => setStarSelected(item)}
                        />
                      </div>
                    ) : (
                      <div className="cursor-pointer">
                        <FontAwesomeIcon
                          icon={faStar}
                          className="text-2xl text-gray-light opacity-60"
                          onClick={() => setStarSelected(item)}
                        />
                      </div>
                    );
                  })}
                  <div className="text-xl sm:text-2xl mr-3 text-gray">ดีมาก</div>
                </div>
              </div>
              <div className="py-4">
                <span className="text-2xl">มารยาทการให้บริการ</span>
                <div className="flex gap-x-3">
                  <div className="text-xl text-gray">ปรับปรุง</div>
                  {[1, 2, 3, 4].map((item, index) => {
                    return item <= starSelected2 ? (
                      <div className="cursor-pointer">
                        <FontAwesomeIcon
                          icon={faStar}
                          className="text-2xl text-yellow"
                          onClick={() => setStarSelected2(item)}
                        />
                      </div>
                    ) : (
                      <div className="cursor-pointer">
                        <FontAwesomeIcon
                          icon={faStar}
                          className="text-2xl text-gray-light opacity-60"
                          onClick={() => setStarSelected2(item)}
                        />
                      </div>
                    );
                  })}
                  <div className="text-xl sm:text-2xl mr-3 text-gray">ดีมาก</div>
                </div>
              </div>
              <div className="py-4">
                <span className="text-2xl">การแต่งกาย</span>
                <div className="flex gap-x-3">
                  <div className="text-xl text-gray">ปรับปรุง</div>
                  {[1, 2, 3, 4].map((item, index) => {
                    return item <= starSelected3 ? (
                      <div className="cursor-pointer">
                        <FontAwesomeIcon
                          icon={faStar}
                          className="text-2xl text-yellow"
                          onClick={() => setStarSelected3(item)}
                        />
                      </div>
                    ) : (
                      <div className="cursor-pointer">
                        <FontAwesomeIcon
                          icon={faStar}
                          className="text-2xl text-gray-light opacity-60"
                          onClick={() => setStarSelected3(item)}
                        />
                      </div>
                    );
                  })}
                  <div className="text-xl sm:text-2xl mr-3 text-gray">ดีมาก</div>
                </div>
              </div>
              <div className="py-4">
                <span className="text-2xl">การแก้ปัญหา</span>
                <div className="flex gap-x-3">
                  <div className="text-xl text-gray">ปรับปรุง</div>
                  {[1, 2, 3, 4].map((item, index) => {
                    return item <= starSelected4 ? (
                      <div className="cursor-pointer">
                        <FontAwesomeIcon
                          icon={faStar}
                          className="text-2xl text-yellow"
                          onClick={() => setStarSelected4(item)}
                        />
                      </div>
                    ) : (
                      <div className="cursor-pointer">
                        <FontAwesomeIcon
                          icon={faStar}
                          className="text-2xl text-gray-light opacity-60"
                          onClick={() => setStarSelected4(item)}
                        />
                      </div>
                    );
                  })}
                  <div className="text-xl sm:text-2xl mr-3 text-gray">ดีมาก</div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div
          className="flex items-center justify-center mb-9"
          onClick={() => onClick({
            rating1: starSelected,
            rating2: starSelected2,
            rating3: starSelected3,
            rating4: starSelected4
          })}
        >
          <div className="btn-green-square py-2 flex justify-center items-center text-2xl w-[170px] sm:w-auto">
            ยืนยัน
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalReview