import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePen,
  faCircleChevronRight,
  faCircleChevronLeft,
  faCalendarDay,
} from "@fortawesome/free-solid-svg-icons";

const RepairDetail2 = () => {
  const location = useLocation();
  const [repair, setRepair] = useState("ระบบไฟ");
  const [request, setRequest] = useState("");

  useEffect(() => {
    setRequest(new URLSearchParams(location.search).get("request"));
    switch (request) {
      case "electrical":
        setRepair("ระบบไฟ");
        break;
      case "water":
        setRepair("ระบบน้ำ");
        break;
      case "electrical-appliance":
        setRepair("เครื่องใช้ไฟฟ้า");
        break;
      case "structure":
        setRepair("โครงสร้าง");
        break;
      case "maintenance":
        setRepair("บริการและซ่อมบำรุง");
        break;
      case "miscellaneou":
        setRepair("เบ็ดเตล็ด");
        break;
      default:
        setRepair("ระบบไฟ");
    }
  }, [location]);

  return (
    <div>
      <div className="bg-white w-100 hidden sm:flex items-center flex-col py-4">
        <span className="text-3xl">{repair}</span>
        <div className="gap-4 flex">
          <Link to="/">
            <span className="text-xl">หน้าหลัก</span>
          </Link>
          <span className="text-xl">•</span>
          <Link to="/repair">
            <span className="text-xl">แจ้งซ่อม</span>
          </Link>
          <span className="text-xl">•</span>
          <span className="text-xl">{repair}</span>
        </div>
      </div>
      <div className="container max-w-3xl mx-auto py-10">
        <div className="flex justify-center items-center pb-[36px] mx-2 sm:mx-0 gap-x-3 sm:gap-x-9">
          <Link to={`/repair-detail?request=${request}`} className="">
            <div className="flex justify-center items-center gap-x-3 py-2 rounded-full bg-[#6fd4bb] w-[140px] sm:w-[180px]">
              <div className="flex justify-center items-center bg-white rounded-full w-[30px] h-[30px] sm:w-[55px] sm:h-[55px]">
                <FontAwesomeIcon
                  icon={faFilePen}
                  className="text-[#6fd4bb] text-xl sm:text-2xl ml-1 sm:ml-2"
                />
              </div>
              <div className="mr-2">
                <div className="sm:text-2xl">STEP 1</div>
                <div className="text-sm sm:text-lg">กรอกรายละเอียด</div>
              </div>
            </div>
          </Link>
          <div className="flex-shrink-0 flex justify-center items-center w-[26px] h-[26px]">
            <FontAwesomeIcon
              icon={faCircleChevronRight}
              className="text-[#D4D4D5] bg-[#999999] w-full h-full rounded-full"
            />
          </div>
          <div className="flex justify-center items-center gap-x-3 py-2 rounded-full bg-[#6fd4bb] w-[140px] sm:w-[180px]">
            <div className="flex justify-center items-center bg-white rounded-full w-[30px] h-[30px] sm:w-[55px] sm:h-[55px]">
              <FontAwesomeIcon
                icon={faCalendarDay}
                className="text-[#6fd4bb] text-xl sm:text-2xl"
              />
            </div>
            <div className="mr-2">
              <div className="sm:text-2xl">STEP 2</div>
              <div className="text-sm sm:text-lg">เลือกวัน / เวลา</div>
            </div>
          </div>
        </div>
        <div className="bg-white rounded-lg mb-9 mx-2 divide-y-[12px] divide-[#f9fafb] shadow-[3.6px_10.4px_30px_0_rgba(228,231,234,0.46)]">
          <div className="px-5 sm:px-12 pt-6 pb-8">
            <div className="flex justify-between items-center mb-7">
              <div className="flex-shrink-0 px-2 sm:px-5 py-1 text-2xl sm:text-3xl bg-[#d3d3d4] rounded-lg ">
                Today
              </div>
              <div className="flex-shrink-0 text-2xl sm:text-3xl font-bold mt-3 ml-5 sm:ml-12">
                JULY 2022
              </div>
              <div className="flex-shrink-0 flex gap-x-2 sm:gap-x-5">
                <div className="flex justify-center items-center py-2 px-4 sm:px-6 border-2 border-[#dfdede] rounded-lg">
                  <FontAwesomeIcon
                    icon={faCircleChevronLeft}
                    className="text-[#D4D4D5] bg-[#999999] sm:w-[30px] sm:h-[30px] rounded-full"
                  />
                </div>
                <div className="flex justify-center items-center py-1 px-4 sm:px-6 border-2 border-[#dfdede] rounded-lg">
                  <FontAwesomeIcon
                    icon={faCircleChevronRight}
                    className="text-[#D4D4D5] bg-[#999999] sm:w-[30px] sm:h-[30px] rounded-full"
                  />
                </div>
              </div>
            </div>
            <div className="h-[250px] w-full border-2 border-[#dfdede]"></div>
          </div>
          <div className="px-3 sm:px-11 pt-6 pb-9">
            <div className="flex-grow flex justify-between items-baseline px-3 sm:px-9 py-1.5 border-[1px] border-[#797879] border-dashed rounded-full bg-[#e8e8e9]">
              <div className="flex items-baseline gap-x-3">
                <div className="">
                  <FontAwesomeIcon
                    icon={faCalendarDay}
                    className="text-xl sm:text-2xl"
                  />
                </div>
                <div className="text-xl sm:text-2xl">26 July 2022</div>
              </div>
              <div>
                <span className="text-xl sm:text-2xl mr-6">ช่วงเช้า</span>
                <span className="text-xl sm:text-2xl">09.00 - 12.00 น.</span>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white rounded-lg mb-9 mx-2 px-5 sm:px-12 pt-8 pb-12  shadow-[3.6px_10.4px_30px_0_rgba(228,231,234,0.46)]">
          <div className="text-3xl mb-3">รายการแจ้งซ่อม</div>
          <div className="rounded-[25px] pl-3 pr-4 sm:pl-[45px] sm:pr-[25px] pb-5 border-2 border-[#797879] border-dashed bg-[#f9fafb] divide-y-2 divide-dashed divide-[#797879]">
            <div className="py-3 sm:py-6">
              <div className="text-2xl sm:text-3xl">รายการที่ 1</div>
              <div className="flex gap-x-5">
                <div className="text-xl sm:text-2xl px-5 bg-black text-white rounded-lg">
                  ระบบไฟ
                </div>
                <div className="text-xl sm:text-2xl">หลอดไฟชำรุด</div>
              </div>
            </div>
            <div className="py-3 sm:py-6">
              <div className="text-2xl sm:text-3xl">รายการที่ 2</div>
              <div className="flex gap-x-5">
                <div className="text-xl sm:text-2xl px-5 bg-black text-white rounded-lg">
                  เครื่องใช้ไฟฟ้า
                </div>
                <div className="text-xl sm:text-2xl">ปั้มน้ำชำรุด</div>
              </div>
            </div>
          </div>
        </div>
        <div className="mx-2 mt-5 mb-12 flex justify-between">
          <Link to={`/repair-detail?request=${request}`}>
            <div className="btn-gray-square flex justify-between items-center w-44 sm:w-auto">
              <div className="px-3 py-1.5">
                <div className="flex justify-center items-center bg-[#474747] rounded-full">
                  <FontAwesomeIcon
                    icon={faCircleChevronLeft}
                    className="text-[#9A9A9A]"
                  />
                </div>
              </div>
              <div className="flex-grow flex justify-center items-center py-2 text-lg">
                กลับ
              </div>
            </div>
          </Link>
          <Link to={`/repair-detail-summary?request=${request}`} className="w-44 sm:w-auto">
            <div className="btn-black-square flex justify-center items-center py-2 text-lg">
              ยืนยันการนัดหมาย
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RepairDetail2;
