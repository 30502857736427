import axios from "axios";
import { Component } from "react";
import { setHeaderAuth } from "../utils";

class ApiComplain extends Component {

    static getTopicComplain = async () => {
        await setHeaderAuth();
        const result = await axios({
            url: "Complain/Topic",
            method: "get",
        });
        return result;
    };

    static getHistory = async () => {
        await setHeaderAuth();
        const result = await axios({
            url: "Complain/History",
            method: "get",
        });
        return result;
    };

    static InsertComplain = async (data) => {
        await setHeaderAuth();
        const result = await axios({
            url: "Complain",
            method: "post",
            data: data,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        });
        return result;
    };

    static updateRating = async (data) => {
        await setHeaderAuth();
        const result = await axios({
            url: `Complain/Rating`,
            method: "post",
            data: data
        });
        return result;
    };

}

export default ApiComplain;