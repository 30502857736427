import { useSelector } from "react-redux";
import HomeComponentMenu from "./components/menu";
import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import CSwlAlert from "../../utils/alert";
import ApiCatalog from "../../api/ApiCatalog";

const Home = () => {
    const userState = useSelector((state) => state.user);

    const [menus, setmenus] = useState([{
        name_th: "แจ้งซ่อม",
        name_en: "Repair Request",
        link: "/repair"
    },
    {
        name_th: "แจ้งซ่อมสาธารณูปโภค",
        name_en: "Repair Request",
        link: "/repairUtils"
    },
    {
        name_th: "แจ้งเรื่องร้องเรียน",
        name_en: "Complain",
        link: "/complain"
    },
    {
        name_th: "ข้อเสนอแนะ",
        name_en: "Suggestion",
        link: "/suggestion"
    },
    {
        name_th: "ประวัติรายการ",
        name_en: "Repair History",
        link: "/history"
    }]);

    useEffect(() => {
        CSwlAlert.SwalLoad();
        const promise1 = getCampaign();
        Promise.all([promise1]).then((result) => {
            CSwlAlert.SwalClose();
        })
    }, []);

    const getCampaign = async () => {
        const response = await ApiCatalog.getCampaign();
        if (response.status == 200) {
            const { data } = response.data;
            if (data?.length > 0) {
                const newMenu = [...menus];
                data.map((item, index) => {
                    newMenu.push(
                        {
                            name_th: item.campaignName,
                            name_en: "",
                            link: `/repair-detail?request=${item.catalogId}`
                        }
                    )
                })
                setmenus(newMenu);
            }
        }
    }

    return <div className="container mx-auto py-10">
        <div className="grid grid-cols-1 sm:grid-cols-2 px-4 sm:px-0 gap-4 sm:gap-10">
            {
                menus.map(item => {
                    if (item.name_th == "แจ้งซ่อม" && userState.is_verify == false) return <></>
                    return <HomeComponentMenu name_th={item.name_th}
                        name_en={item.name_en}
                        link={item.link}
                    />;
                })
            }
        </div>

    </div>
}

export default Home;