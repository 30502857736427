import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimesCircle,
  faHomeAlt,
  faTools,
  faCommentAlt,
  faLightbulb,
  faHistory,
  faMobilePhone,
  faPhoneAlt,
  faHeadset,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";

const SideBar = ({ sidebar, setSidebar, menus = [] }) => {
  const userState = useSelector((state) => state.user);
  return (
    <div className={`${!sidebar && "hidden"}`}>
      <div className="flex flex-col divide-y-2 divide-white w-full text-2xl bg-black text-white">
        <div className="flex justify-between py-3 px-3">
          <div></div>
          <div className="ml-9">Menu</div>
          <div onClick={() => setSidebar(false)}>
            <FontAwesomeIcon icon={faTimesCircle} className="text-gray" />
          </div>
        </div>
        <Link to={`/`} onClick={() => setSidebar(false)}>
          <div className="flex gap-x-3 text-2xl px-5 py-3">
            <div className="w-8">
              <FontAwesomeIcon icon={faHomeAlt} className="text-gray" />
            </div>
            <div>หน้าแรก</div>
          </div>
        </Link>
        {userState.is_verify &&
          <Link to={`/repair`} onClick={() => setSidebar(false)}>
            <div className="flex gap-x-3 text-2xl px-5 py-3">
              <div className="w-8">
                <FontAwesomeIcon icon={faTools} className="text-gray" />
              </div>
              <div>แจ้งซ่อม</div>
            </div>
          </Link>
        }

        <Link to={`/repairUtils`} onClick={() => setSidebar(false)}>
          <div className="flex gap-x-3 text-2xl px-5 py-3">
            <div className="w-8">
              <FontAwesomeIcon icon={faTools} className="text-gray" />
            </div>
            <div>แจ้งซ่อมสาธารณูปโภค</div>
          </div>
        </Link>

        {
          menus.map((item, index) => {
            return <Link to={item.link} onClick={() => setSidebar(false)}>
              <div className="flex gap-x-3 text-2xl px-5 py-3">
                <div className="w-8">
                  <FontAwesomeIcon icon={faTools} className="text-gray" />
                </div>
                <div> {item.name_th}</div>
              </div>
            </Link>
          })
        }

        <Link to={`/complain`} onClick={() => setSidebar(false)}>
          <div className="flex gap-x-3 text-2xl px-5 py-3">
            <div className="w-8">
              <FontAwesomeIcon icon={faCommentAlt} className="text-gray" />
            </div>
            <div>แจ้งเรื่องร้องเรียน</div>
          </div>
        </Link>
        <Link to={`/suggestion`} onClick={() => setSidebar(false)}>
          <div className="flex gap-x-3 text-2xl px-5 py-3">
            <div className="w-8">
              <FontAwesomeIcon icon={faLightbulb} className="text-gray" />
            </div>
            <div>ข้อเสนอแนะ</div>
          </div>
        </Link>
        <Link to={`/history`} onClick={() => setSidebar(false)}>
          <div className="flex gap-x-3 text-2xl px-5 py-3">
            <div className="w-8">
              <FontAwesomeIcon icon={faHistory} className="text-gray" />
            </div>
            <div>ประวัติรายการ</div>
          </div>
        </Link>
        <div className="flex gap-x-3 text-2xl px-5 py-3">
          <div className="w-8">
            <FontAwesomeIcon icon={faMobilePhone} className="text-gray" />
          </div>
          <div>ข่าวสาร</div>
        </div>
        <div className="flex gap-x-3 text-2xl px-5 py-3">
          <div className="w-8">
            <FontAwesomeIcon icon={faPhoneAlt} className="text-gray" />
          </div>
          <div>ติดต่อเรา</div>
        </div>
        <div className="px-3 pt-20">
          <div
            className="bg-center bg-contain bg-no-repeat h-10 w-40 cursor-pointer"
            style={{
              backgroundImage: `url("${process.env.PUBLIC_URL}/images/logo.png")`,
            }}
          ></div>
          <div className="flex gap-x-3">
            <FontAwesomeIcon icon={faHeadset} className="text-gray" />
            สายด่วน Family :
          </div>
          <div className="text-4xl font-bold">1797</div>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
