import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDay,
  faSpinner,
  faMapMarkerAlt,
  faCircleCheck,
  faCircleChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import ApiTransaction from "../../api/ApiTransaction";
import CSwlAlert from "../../utils/alert";
import { ConvertDate, getPeriod } from "../../utils";

const CancleRepair = () => {
  const [state, setstate] = useState({
    reason: "",

  });
  const history = useHistory();
  const params = useParams();
  const id = params.id;
  const location = useLocation();
  const maRef = new URLSearchParams(location.search).get("request")
  const [repair, setRepair] = useState("ระบบไฟ");
  const [order, setOrder] = useState('');

  useEffect(() => {
    CSwlAlert.SwalLoad();
    const promise1 = getTransaction();
    Promise.all([promise1]).then((result) => {
      CSwlAlert.SwalClose();
    })
  }, []);

  const getTransaction = async () => {
    try {
      const response = await ApiTransaction.getRef(id);
      if (response.status == 200) {
        const { data } = response.data;
        console.log(data)
        setOrder(data);
      }
    } catch (error) {
      const { data } = error.response;
      CSwlAlert.SwalErr({ description: data.message }).then((result) => {
        history.go(-1)
      });
    }

  }

  const updateRef = async (e) => {
    e.preventDefault();
    CSwlAlert.SwalLoad();
    try {
      const response = await ApiTransaction.update({
        maRef: id,
        maStatus: "CC",
        maReason: state.reason
      });
      if (response.status == 200) {
        CSwlAlert.SwalSuccess("", { html: "ยกเลิกสำเร็จ" }).then((result) => {
          history.push('/history');
        })
      }
    } catch (error) {
      const { data } = error.response;
      CSwlAlert.SwalErr({ description: data.message })
    }
  }



  return (
    <div>
      <div className="bg-white w-100 hidden sm:flex items-center flex-col py-4">
        <span className="text-3xl">ยกเลิกบริการ</span>
        <div className="gap-4 flex">
          <Link to="/">
            <span className="text-xl">หน้าหลัก</span>
          </Link>
          <span className="text-xl">•</span>
          <Link to="/history">
            <span className="text-xl">ประวัติรายการแจ้งซ่อม</span>
          </Link>
          <span className="text-xl">•</span>
          <Link to={`/repair-status/${id}`}>
            <span className="text-xl">เลขที่แจ้งซ่อม : {id}</span>
          </Link>
          <span className="text-xl">•</span>
          <span className="text-xl">ยกเลิกบริการ</span>
        </div>
      </div>
      <form onSubmit={updateRef}>
        <div className="container max-w-3xl mx-auto py-10">
          <div className="bg-white rounded-lg mb-9 mx-2 divide-y-[12px] divide-[#f9fafb] shadow-[3.6px_10.4px_30px_0_rgba(228,231,234,0.46)]">
            <div className="flex justify-between px-3 sm:px-12 py-6">
              <div className="flex items-end sm:items-baseline gap-x-2 sm:gap-x-3">
                <div className="text-xl sm:text-3xl">ประเภท</div>
                <div className="text-xl sm:text-3xl text-white bg-black px-3 sm:px-6 rounded-full">
                  {"แจ้งซ่อม"}
                </div>
                <div className="text-sm sm:text-2xl">
                  เลขที่แจ้งซ่อม : {id}
                </div>
              </div>
              {repair.status === "finish" ? (
                <div className="flex gap-x-3 justify-center items-center sm:px-4 rounded-lg bg-green-500 w-[115px] sm:w-[200px]">
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    className="text-xl sm:text-2xl text-gray bg-black rounded-full"
                  />
                  <div className="text-lg sm:text-2xl">เสร็จแล้ว</div>
                </div>
              ) : repair.status === "wait_inspect" ? (
                <div className="flex gap-x-3 justify-center items-center sm:px-4 rounded-lg bg-yellow w-[115px] sm:w-[200px]">
                  <FontAwesomeIcon
                    icon={faSpinner}
                    className="text-xl sm:text-2xl text-gray"
                  />
                  <div className="text-lg sm:text-2xl">รอตรวจรับงาน</div>
                </div>
              ) : (
                (order?.main?.mastatus === "WA" || order?.main?.mastatus === "AF") && (
                  <div className="flex justify-center items-center gap-x-3 sm:px-4 rounded-lg bg-[#fbf2c9] w-[115px] sm:w-[200px]">
                    <FontAwesomeIcon
                      icon={faSpinner}
                      className="text-lg sm:text-2xl text-gray"
                    />
                    <div className="text-lg sm:text-2xl">รอดำเนินการ</div>
                  </div>
                )
              )}
            </div>
            {
              order && <div className="py-6 px-5 sm:px-12">
                <div className="flex items-center gap-x-3">
                  <FontAwesomeIcon
                    icon={faCalendarDay}
                    className="text-xl sm:text-2xl text-gray"
                  />
                  <div className="sm:text-2xl">
                    <span className="text-gray">วันเวลาที่รับบริการ :</span>{" "}
                    {`${ConvertDate(order.main.madate, 'วันddd ที่ DD MMM yyyy', 'th')} (${getPeriod(order.main.period)})`}
                  </div>
                </div>
                <div className="flex items-center gap-x-3 mt-3">
                  <FontAwesomeIcon
                    icon={faMapMarkerAlt}
                    className="text-xl sm:text-2xl text-gray"
                  />
                  <div className="sm:text-2xl">
                    <span className="text-gray">สถานที่ :</span> {order.user.addressNo}
                  </div>
                </div>
              </div>
            }
          </div>
          <div className="bg-white rounded-lg mb-9 mx-2 divide-y-[12px] divide-[#f9fafb] shadow-[3.6px_10.4px_30px_0_rgba(228,231,234,0.46)]">
            <div className="px-5 py-9 sm:p-12 flex flex-col">
              <span className="text-xl required">ผลเหตุการแจ้งยกเลิกบริการ</span>
              <input
                required
                onChange={(e) => {
                  setstate({ ...state, reason: e.target.value })
                }}
                value={state.reason}
                //   placeholder="กรุณากรอกรายละเอียดเรื่องร้องเรียน"
                className="form-control text-xl"
              />
            </div>
          </div>
          <div className="mt-5 mb-12 mx-2 flex justify-between">
            <Link to={`/repair-status/${id}`}>
              <div className="btn-gray-square flex justify-between items-center w-[170px] sm:w-auto">
                <div className="px-3 py-1.5">
                  <div className="flex justify-center items-center bg-[#474747] rounded-full">
                    <FontAwesomeIcon
                      icon={faCircleChevronLeft}
                      className="text-[#9A9A9A]"
                    />
                  </div>
                </div>
                <div className="flex-grow flex justify-center items-center py-2 text-lg">
                  กลับ
                </div>
              </div>
            </Link>
            {/* <Link to={`/history`}> */}
            <button type="submit" className="btn-black-square flex justify-center items-center py-2 text-lg w-[170px] sm:w-auto">
              ยืนยัน
            </button>
            {/* </Link> */}
          </div>
        </div>
      </form>
    </div>
  );
};

export default CancleRepair;
