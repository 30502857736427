import axios from "axios";
axios.defaults.baseURL = process.env.REACT_APP_API;

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      window.location.href = "/signin";
    }
    throw error;
  }
);
