import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDay,
  faSpinner,
  faMapMarkerAlt,
  faCircleCheck,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import ModalReview from "../../components/ModalReview";
import ApiTransaction from "../../api/ApiTransaction";
import CSwlAlert from "../../utils/alert";
import { ConvertDate, getPeriod } from "../../utils";
import { Tab } from '@headlessui/react'
import ApiComplain from "../../api/ApiComplain";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const History = () => {
  const history = useHistory();

  const [orderHistory, setorderHistory] = useState([]);
  const [complainHistory, setcomplainHistory] = useState([]);

  const [modal, setModal] = useState(false);

  const [modalReview, setModalReview] = useState(false);

  useEffect(() => {
    CSwlAlert.SwalLoad();
    const promise1 = getHistory();
    const promise2 = getHistoryComplain();
    Promise.all([promise1, promise2]).then((result) => {
      CSwlAlert.SwalClose();
    })
  }, []);

  const getHistory = async () => {
    try {
      const response = await ApiTransaction.getHistory();
      if (response.status == 200) {
        const { data } = response.data;
        console.log(data);
        setorderHistory(data);
      }
    } catch (error) {
      const { data } = error.response;
      CSwlAlert.SwalErr({ description: data.message }).then((result) => {
        history.go(-1)
      });
    }

  }

  const getHistoryComplain = async () => {
    try {
      const response = await ApiComplain.getHistory();
      if (response.status == 200) {
        const { data } = response.data;
        console.log(data);
        setcomplainHistory(data);
      }
    } catch (error) {
      const { data } = error.response;
      CSwlAlert.SwalErr({ description: data.message }).then((result) => {
        history.go(-1)
      });
    }

  }

  const sendRating = async (model, ref) => {
    try {
      const response = await ApiComplain.updateRating({
        ...model,
        maRef: ref,
        // maRef: id
      });
      if (response.status == 200) {
        setModalReview(false);
        CSwlAlert.SwalSuccess("", { html: "ให้คะแนนสำเร็จ" }).then((result) => {
          getHistoryComplain();
        })
      }
    } catch (error) {
      const { data } = error.response;
      CSwlAlert.SwalErr({ description: data.message })
    }
  }

  return (
    <div>
      <div className="bg-white w-100 hidden sm:flex items-center flex-col py-4">
        <span className="text-3xl">ประวัติรายการ</span>
        <div className="gap-4 flex">
          <Link to="/">
            <span className="text-xl">หน้าหลัก</span>
          </Link>
          <span className="text-xl">•</span>
          <span className="text-xl">ประวัติรายการแจ้งซ่อม</span>
        </div>
      </div>

      <div className="container max-w-3xl mx-auto py-10">
        <Tab.Group>
          <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
            <Tab
              key={"ประวัติแจ้งซ่อม"}
              className={({ selected }) =>
                classNames(
                  'text-2xl bg-[#f4f4f4] w-full rounded-lg py-2.5 font-medium leading-5 text-blue-[#1d4ed8]',
                  'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                  selected
                    ? '!bg-white shadow'
                    : 'text-blue-[#dbeafe] hover:bg-white/[0.12] hover:text-gray'
                )
              }
            >
              {"ประวัติแจ้งซ่อม"}
            </Tab>
            <Tab
              key={"ประวัติร้องเรียน"}
              className={({ selected }) =>
                classNames(
                  'text-2xl bg-[#f4f4f4] w-full rounded-lg py-2.5 font-medium leading-5 text-blue-[#1d4ed8]',
                  'ring-white focus: ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                  selected
                    ? '!bg-white shadow'
                    : 'text-blue-[#dbeafe] hover:bg-white/[0.12] hover:text-gray'
                )
              }
            >
              {"ประวัติร้องเรียน"}
            </Tab>
          </Tab.List>
          <Tab.Panels className="mt-2">
            <Tab.Panel
              key={"ประวัติแจ้งซ่อม"}
            // className={classNames(
            //   'rounded-xl bg-white p-3',
            //   'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
            // )}
            >
              {orderHistory.main && orderHistory.main.map((item, index) => {
                return (
                  <div className="bg-white rounded-lg mb-9 mx-2 divide-y-[12px] divide-[#f9fafb]  shadow-[3.6px_10.4px_30px_0_rgba(228,231,234,0.46)]">
                    <div className="flex justify-between px-3 sm:px-12 py-6">
                      <div className="flex items-end sm:items-baseline gap-x-2 sm:gap-x-3 flex-wrap">
                        <div className="text-xl sm:text-3xl">ประเภท</div>
                        <div className="text-xl sm:text-3xl px-2 sm:px-4 rounded-lg bg-gray-light">
                          แจ้งซ่อม
                        </div>
                        <div className="text-sm sm:text-2xl">
                          เลขที่แจ้งซ่อม : {item.main.maref}
                        </div>
                        {item.main.isUrgent &&
                          <div>
                            <span className="text-red-600">*Urgent (รอรับสายจาก Foreman)</span>
                          </div>
                        }
                      </div>


                      {item.main.mastatus === "CP" ? (
                        <div className="flex h-[fit-content] py-1 gap-x-3 justify-center items-center px-4 rounded-lg bg-green-500 w-[115px] sm:w-[200px]">
                          <FontAwesomeIcon
                            icon={faCircleCheck}
                            className="text-xl sm:text-2xl text-gray bg-black rounded-full"
                          />
                          <div className="text-lg sm:text-2xl">เสร็จแล้ว</div>
                        </div>
                      ) : item.main.mastatus === "FS" ? (
                        <div className="flex h-[fit-content] py-1 gap-x-3 justify-center items-center px-1 sm:px-4 rounded-lg bg-yellow w-[115px] sm:w-[200px]">
                          <FontAwesomeIcon
                            icon={faSpinner}
                            className="text-xl sm:text-2xl text-gray"
                          />
                          <div className="text-lg sm:text-2xl">รอตรวจรับงาน</div>
                        </div>
                      ) : item.main.mastatus === "WR" ? (
                        <div className="flex h-[fit-content] py-1 gap-x-3 justify-center items-center px-1 sm:px-4 rounded-lg bg-yellow w-[115px] sm:w-[200px]">
                          <FontAwesomeIcon
                            icon={faSpinner}
                            className="text-xl sm:text-2xl text-gray"
                          />
                          <div className="text-lg sm:text-2xl">รอให้คะแนน</div>
                        </div>
                      ) : (
                        (item.main.mastatus === "WA" || item?.main?.mastatus === "AF") && (
                          <div className="flex h-[fit-content] py-1 justify-center items-center gap-x-3 px-2 sm:px-4 rounded-lg bg-[#fbf2c9] w-[115px] sm:w-[200px]">
                            <FontAwesomeIcon
                              icon={faSpinner}
                              className="text-xl sm:text-2xl text-gray"
                            />
                            <div className="text-lg sm:text-2xl">{item.main.madate ? "รอดำเนินการ" : "รอนัดหมาย"}</div>
                          </div>
                        )
                      )}
                    </div>

                    <div className="py-6 px-5 sm:px-12">
                      {
                        item.main.madate && <div className="flex items-center gap-x-3">
                          <FontAwesomeIcon
                            icon={faCalendarDay}
                            className="text-xl sm:text-2xl text-gray"
                          />
                          <div className="sm:text-2xl">
                            <span className="text-gray">วันเวลาที่รับบริการ :</span>{" "}
                            {`${ConvertDate(item.main.madate, 'วันddd ที่ DD MMM yyyy', 'th')} (${getPeriod(item.main.period)})`}
                          </div>
                        </div>
                      }

                      <div className="flex items-center gap-x-3 mt-2">
                        <FontAwesomeIcon
                          icon={faMapMarkerAlt}
                          className="text-xl sm:text-2xl text-gray"
                        />
                        <div className="sm:text-2xl">
                          <span className="text-gray">สถานที่ :</span> {orderHistory.user.addressNo}
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-between py-6 px-5 sm:px-12">
                      {item.main.mastatus === "W" ? (
                        <Link to={`/repair-postpone/${item.main.maref}`}>
                          <div className="btn-yellow-square flex justify-center text-xl py-2 w-[170px] sm:w-auto">
                            ขอเลื่อนบริการ
                          </div>
                        </Link>
                      ) : item.status === "finish" ? (
                        item.review === true ? (
                          <div className="flex  sm:gap-x-3 justify-center items-center text-xl py-2">
                            <span className="text-2xl mr-2 sm:mr-0">คะแนน</span>
                            {[1, 2, 3, 4, 5].map((num, index) => {
                              return num <= item.star ? (
                                <FontAwesomeIcon
                                  icon={faStar}
                                  className="text-xl sm:text-2xl text-yellow"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  icon={faStar}
                                  className="text-xl sm:text-2xl text-gray-light opacity-60"
                                />
                              );
                            })}
                          </div>
                        ) : (
                          <div className="btn-black-square flex justify-center text-xl py-2 w-[170px] sm:w-auto" onClick={() => setModal(true)}>
                            ประเมินผลงาน
                          </div>
                        )
                      ) : (
                        <div></div>
                      )}
                      <Link to={`/repair-status/${item.main.maref}`}>
                        <div className="btn-green-square flex justify-center text-xl py-2 w-[170px] sm:w-auto">
                          ดูรายละเอียดการแจ้งซ่อม
                        </div>
                      </Link>
                    </div>
                    {item.status === "finish" && item.review === false && modal && (
                      <ModalReview setModal={setModal} repair={item} />
                    )}
                  </div>
                );
              })}
            </Tab.Panel>
            <Tab.Panel
              key={"ประวัติร้องเรียน"}
            >
              {complainHistory.length > 0 && complainHistory.map((item, index) => {
                return (
                  <div className="bg-white rounded-lg mb-9 mx-2 divide-y-[12px] divide-[#f9fafb]  shadow-[3.6px_10.4px_30px_0_rgba(228,231,234,0.46)]">
                    <div className="flex justify-between px-3 sm:px-12 py-6">
                      <div className="flex items-end sm:items-baseline gap-x-2 sm:gap-x-3 flex-wrap">
                        <div className="text-xl sm:text-3xl">ประเภท</div>
                        <div className="text-xl sm:text-3xl px-2 sm:px-4 rounded-lg bg-gray-light">
                          ร้องเรียน
                        </div>
                        <div className="text-sm sm:text-2xl">
                          เลขที่ร้องเรียน : {item.tranComplain.cpRef}
                        </div>
                      </div>


                      {item.tranComplain.complainStatus === "IP" ? (
                        <div className="flex h-[fit-content] py-1 gap-x-3 justify-center items-center px-1 sm:px-4 rounded-lg bg-yellow w-[115px] sm:w-[200px]">
                          <FontAwesomeIcon
                            icon={faSpinner}
                            className="text-xl sm:text-2xl text-gray"
                          />
                          <div className="text-lg sm:text-2xl">กำลังดำเนินการ</div>
                        </div>
                      ) : item.tranComplain.complainStatus === "CL" ? (
                        <div className="flex h-[fit-content] py-1 gap-x-3 justify-center items-center px-1 sm:px-4 rounded-lg bg-green-500 w-[115px] sm:w-[200px]">
                          <FontAwesomeIcon
                            icon={faCircleCheck}
                            className="text-xl sm:text-2xl text-gray bg-black rounded-full"
                          />
                          <div className="text-lg sm:text-2xl">เสร็จแล้ว</div>
                        </div>
                      ) : (
                        (item.tranComplain.complainStatus === "OP") ? (
                          <div className="flex h-[fit-content] py-1 justify-center items-center gap-x-3 px-2 sm:px-4 rounded-lg bg-[#fbf2c9] w-[115px] sm:w-[200px]">
                            <FontAwesomeIcon
                              icon={faSpinner}
                              className="text-xl sm:text-2xl text-gray"
                            />
                            <div className="text-lg sm:text-2xl">รอดำเนินการ</div>
                          </div>
                        ) : (item.tranComplain.complainStatus === "WR") &&
                        <div className="flex h-[fit-content] py-1 gap-x-3 justify-center items-center px-1 sm:px-4 rounded-lg bg-yellow w-[115px] sm:w-[200px]">
                          <FontAwesomeIcon
                            icon={faSpinner}
                            className="text-xl sm:text-2xl text-gray"
                          />
                          <div className="text-lg sm:text-2xl">รอให้คะแนน</div>
                        </div>
                      )}
                    </div>
                    <div className="py-6 px-5 sm:px-12">
                      <div className="flex flex-col gap-x-3 gap-y-2 mt-2">
                        <div className="sm:text-2xl">
                          <span className="text-gray">หัวข้อร้องเรียน :</span> {item.mtComplain?.complain}
                        </div>
                        <div className="sm:text-2xl">
                          <span className="text-gray">รายละเอียดการร้องเรียน :</span> {item.tranComplain?.complainDesc}
                        </div>

                        <div className="sm:text-2xl flex gap-x-4 flex-wrap">
                          <span className="text-gray">รูปภาพ :</span>
                          <div className="flex flex-wrap gap-2">
                            {
                              item.tranComplainFiles.length > 0 &&
                              item.tranComplainFiles.map((image, imageIndex) => {
                                return <img src={image.type == "BE" ? process.env.REACT_APP_IMAGE_BACK + image.pathFile : process.env.REACT_APP_IMAGE + image.pathFile}
                                  className="flex-shrink-0 w-[80px] h-[80px] sm:w-[121px] sm:h-[121px] rounded-lg border-2 border-gray" />
                              })
                            }
                          </div>
                        </div>
                      </div>
                      {
                        (item.tranComplain.complainStatus === "WR") &&
                        <div className="flex w-full justify-end">
                          {modalReview && <ModalReview setModal={setModalReview} onClick={(payload) => {
                            sendRating(payload, item.tranComplain.cpRef)
                          }} />}
                          <div
                            className="btn-gray-square !bg-[#efefef] flex justify-center items-center gap-x-3 text-xl py-2 w-[170px] sm:w-auto"
                            onClick={() => setModalReview(true)}
                          >
                            <FontAwesomeIcon
                              icon={faStar}
                              className="text-yellow"
                            />
                            ให้คะแนน
                          </div>
                        </div>
                      }

                    </div>
                  </div>
                );
              })}

            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>

      </div>
    </div>
  );
};

export default History;
