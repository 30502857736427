import { Link } from "react-router-dom"

const RepairComponentMenu = ({ name_th, name_en, link }) => {
    return <>
        <div className="p-5 h-32 rounded-lg flex bg-white shadow-sm">
            <div className="flex flex-col justify-between flex-1">
                <div className="flex flex-1 flex-col">
                    <span className="text-2xl font-semibold">{name_th}</span>
                    <span className="text-base">{name_en}</span>
                </div>
                <Link to={`${link}`}>
                    <span className="btn-black w-20 rounded-21">แจ้งซ่อม</span>
                </Link>
            </div>
        </div>
    </>
}

export default RepairComponentMenu