import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ApiCatalog from "../../api/ApiCatalog";
import CSwlAlert from "../../utils/alert";
import RepairComponentMenu from "./components/menu";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const Repair = () => {
    const location = useLocation();
    const [catalogs, setCatalogs] = useState([]);
    const campaignCode = new URLSearchParams(location.search).get("campaignCode")
    useEffect(() => {
        CSwlAlert.SwalLoad();
        if (campaignCode) {
            const promise1 = getCatalogsCampaign();
            Promise.all([promise1]).then((result) => {
                CSwlAlert.SwalClose();
            })
        } else {
            const promise1 = getCatalogs();
            Promise.all([promise1]).then((result) => {
                CSwlAlert.SwalClose();
            })
        }
    }, []);

    const getCatalogs = async () => {
        const response = await ApiCatalog.getCatalog();
        if (response.status == 200) {
            const { data } = response.data;
            setCatalogs(data.filter(x=>x.isCampaign != true));
        }
    }

    
    const getCatalogsCampaign = async () => {
        const response = await ApiCatalog.getCampaignCatalog(campaignCode);
        if (response.status == 200) {
            const { data } = response.data;
            setCatalogs(data);
        }
    }

    return <>
        <div className="bg-white w-100 hidden sm:flex items-center flex-col py-4">
            <span className="text-3xl">แจ้งซ่อม</span>
            <div className="gap-4 flex">
                <Link to="/">
                    <span className="text-xl">หน้าหลัก</span>
                </Link>
                <span className="text-xl">•</span>
                <span className="text-xl">แจ้งซ่อม</span>
            </div>
        </div>
        <div className="container mx-auto py-10">
            <div className="grid grid-cols-1 sm:grid-cols-3 px-4 sm:px-0 gap-4 sm:gap-8">
                {
                    catalogs.map(item => {
                        return <RepairComponentMenu name_th={item.catalogname}
                            name_en={item.catalognameEn}
                            link={`/repair-detail?request=${item.id}`}
                        />;
                    })
                }
            </div>

        </div>
    </>
}

export default Repair;