import axios from "axios";
import { Component } from "react";
import { setHeaderAuth } from "../utils";

class ApiConfig extends Component {
    static getConfigByCode = async (code) => {
        await setHeaderAuth();
        const result = await axios({
            url: `Config/${code}`,
            method: "get",
        });
        return result;
    };
    static getProjects = async () => {
        await setHeaderAuth();
        const result = await axios({
            url: `Config/Projects`,
            method: "get",
        });
        return result;
    };
    static getProjectByHouseNo = async (houseNo) => {
        await setHeaderAuth();
        const result = await axios({
            url: `Config/ProjectsByHouseNo?houseNo=${houseNo}`,
            method: "get",
        });
        return result;
    };

    static getProjectFloorAndPosition = async (projectCode) => {
        await setHeaderAuth();
        const result = await axios({
            url: `Config/ProjectFloorAndPosition?projectCode=${projectCode}`,
            method: "get",
        });
        return result;
    };
    
    static getHoliday = async (data) => {
        await setHeaderAuth();
        const result = await axios({
            url: `Config/Holiday`,
            method: "post",
            data: data
        });
        return result;
    };

}

export default ApiConfig;
