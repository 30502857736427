import axios from 'axios';
import moment from 'moment';
import CSwlAlert from './alert';
import { getLocalStorage } from "./localStorage";
import "moment/locale/th";

export const setHeaderAuth = async () => {
    const token = await getLocalStorage("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

export const Currency = (number = 0, format = 0) => {
    if (format == 0) {
        return new Intl.NumberFormat('th-TH', { style: 'currency', currency: 'THB' }).format(number).replace("฿", "").replace(/\D00$/, '');
    } else {
        return new Intl.NumberFormat('th-TH', { style: 'currency', currency: 'THB', minimumFractionDigits: format }).format(number).replace("฿", "");
    }
}

export const ConvertDate = (date, format = "DD/MM/YYYY HH:mm:ss", lang = "en") => {
    return moment(date).locale(lang).format(format);
}

export const CheckFile = ({ file, size, type = [], message = "" }) => {
    if (!type.includes(file.type) && type.length > 0) {
        CSwlAlert.SwalErr(
            `รองรับเฉพาะไฟล์ประเภท ${type.join(",")}`
        );
        return false;
    }
    if (file.size / 1000 / 1000 > size) {
        CSwlAlert.SwalErr(
            `Please upload a file smaller than ${size} MB`
        );
        return false;
    } else {
        return true;
    }
};

export const validateNumber = (inputText) => {
    if (inputText.length == 0) {
        return true;
    }
    var numbers = /^[0-9]+$/;
    if (inputText.match(numbers)) {
        return true;
    } else {
        return false;
    }
}


export const validatePhonenumber = (inputText) => {
    var phoneno = /^\d{10}$/;
    if (inputText.length == 0) {
        return true;
    }
    if (inputText.match(phoneno)) {
        return true;
    } else {
        return false;
    }
}

export const getWeeks = (current) => {
    var week = new Array();
    // Starting Monday not Sunday
    current.setDate((current.getDate() - current.getDay()));
    current.setHours(7, 0, 0, 0);
    for (var i = 0; i < 7; i++) {
        week.push(
            new Date(current)
        );
        current.setDate(current.getDate() + 1);
    }
    return week;
}

export const getPeriod = (period) => {
    switch (period) {
        case 1:
            return "ช่วงเช้า 09:00 - 12:00"
        case 2:
            return "ช่วงบ่าย 13:00 - 17:00"
        case 3:
            return "ช่วงเย็น 18:00 - 21:00"
        case 4:
             return "ช่วงเวลาใดก็ได้"
    }
}


