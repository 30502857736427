import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDay,
  faBell,
  faReceipt,
  faCircle
} from "@fortawesome/free-solid-svg-icons";
import ApiTransaction from "../../api/ApiTransaction";
import CSwlAlert from "../../utils/alert";
import { ConvertDate, getPeriod } from "../../utils";
import { useSelector } from "react-redux";

const RepairSummary = () => {
  const history = useHistory();
  const location = useLocation();
  const dateSelectedList = useSelector((state) => state.dateSelectedList);
  const maRef = new URLSearchParams(location.search).get("request")
  const [repair, setRepair] = useState("ระบบไฟ");
  const [request, setRequest] = useState("");
  const [order, setOrder] = useState('');

  useEffect(() => {
    console.log('dateSelectedList::', dateSelectedList)
    CSwlAlert.SwalLoad();
    const promise1 = getTransaction();
    Promise.all([promise1]).then((result) => {
      CSwlAlert.SwalClose();
    })
  }, []);

  const getTransaction = async () => {
    try {
      const response = await ApiTransaction.getRef(maRef);
      if (response.status == 200) {
        const { data } = response.data;
        setOrder(data);
      }
    } catch (error) {
      const { data } = error.response;
      CSwlAlert.SwalErr({ description: data.message }).then((result) => {
        history.go(-1)
      });
    }

  }

  const updateRef = async () => {
    CSwlAlert.SwalLoad();
    try {
      const response = await ApiTransaction.update({
        maRef: maRef,
        maStatus: "WA",
        appointmentLists: dateSelectedList
      });
      if (response.status == 200) {
        CSwlAlert.SwalSuccess("", { html: "ยืนยันการนัดหมายสำเร็จ" }).then((result) => {
          history.push('/history');
        })
      }
    } catch (error) {
      const { data } = error.response;
      CSwlAlert.SwalErr({ description: data.message })
    }
  }

  return (
    <div>
      <div className="bg-white w-100 flex items-center flex-col py-4">
        <span className="text-3xl">สรุปนัดหมาย</span>
        <div className="gap-4 flex">
          <Link to="/">
            <span className="text-xl">หน้าหลัก</span>
          </Link>
          <span className="text-xl">•</span>
          <Link to="/repair">
            <span className="text-xl">แจ้งซ่อม</span>
          </Link>
          <span className="text-xl">•</span>
          <Link to={`/repair-detail?request=${request}`}>
            <span className="text-xl">{repair}</span>
          </Link>
          <span className="text-xl">•</span>
          <span className="text-xl">สรุปนัดหมาย</span>
        </div>
      </div>
      <div className="flex justify-center items-center py-1 px-2 sm:px-0 gap-x-3 bg-[#fbf2c8]">
        <FontAwesomeIcon icon={faBell} className="text-[#9A9A9A]" />
        <div className="text-lg">
          เพื่อความสะดวกในการรับบริการ Areeya Family
          กรุณาตรวจสอบวันและเวลาอีกครั้งเพื่อตรวจสอบความถูกต้อง
          ในวันที่ทำการนัดหมายจะมีเจ้าหน้าที่ติดต่อท่าน
          โปรดรับโทรศัพท์เพื่อหลีกเลี่ยงการบริการไม่สำเร็จ
        </div>
      </div>
      <div className="container max-w-3xl mx-auto py-10">
        <div className="bg-white rounded-lg sm:mb-9 mx-2 divide-y-[12px] divide-[#f9fafb] shadow-[3.6px_10.4px_30px_0_rgba(228,231,234,0.46)]">
          <div className="flex flex-col items-center py-8 gap-y-2">
            <div className="flex justify-center items-center rounded-full bg-green-500 w-[80px] h-[80px]">
              <FontAwesomeIcon
                icon={faReceipt}
                className="text-[#6A8680] text-3xl"
              />
            </div>
            <div className="text-3xl">สรุปนัดหมาย</div>
          </div>
          <div className="flex justify-between py-5 px-5 sm:px-12">
            <div className="flex gap-x-4">
              <div className="text-xl sm:text-3xl">ประเภท</div>
              <div className="text-xl sm:text-3xl text-white bg-black px-2 sm:px-6 rounded-full">
                แจ้งซ่อม
              </div>
            </div>
            <div className="text-lg sm:text-2xl">เลขที่แจ้งซ่อม : {maRef}</div>
          </div>
          <div className="mb-9 px-5 sm:px-12 pt-8 pb-5 sm:pb-12">
            <div className="text-2xl sm:text-3xl mb-3">รายการแจ้งซ่อม</div>
            <div className="rounded-[25px] pl-4 pr-3 sm:pl-[45px] sm:pr-[25px] pb-5 border-2 border-[#797879] border-dashed bg-[#f9fafb] divide-y-2 divide-dashed divide-[#797879]">
              {
                order.items && <>
                  {order.items.map((item, index) => {
                    return <div key={'list' + index} className="py-6">
                      <div className="text-2xl sm:text-3xl">รายการที่ {index + 1}</div>
                      <div className="flex gap-x-3 sm:gap-x-8 items-center">
                        <img src={item.images?.length > 0 && process.env.REACT_APP_IMAGE + item.images[0].pathFile} className="flex-shrink-0 w-[80px] h-[80px] sm:w-[121px] sm:h-[121px] rounded-lg border-2 border-gray" />
                        <div className="flex flex-col gap-y-3 mt-5 sm:mt-0">
                          <div className="flex gap-x-5">
                            <div className="text-xl sm:text-2xl px-5 bg-black text-white rounded-lg">
                              {item.catalogName}
                            </div>
                            <div className="text-xl sm:text-2xl">{item.subCatalogName}</div>
                          </div>
                          {
                            order.main.madate && <div className="flex sm:items-center gap-x-3">
                              <div className="">
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  className="text-xl sm:text-2xl"
                                />
                              </div>
                              <div className="sm:text-xl">
                                {`วันเวลาที่รับบริการ ${ConvertDate(order.main.madate, 'วันddd ที่ DD MMM yyyy', 'th')} (${getPeriod(order.main.period)})`}
                              </div>
                            </div>
                          }

                        </div>
                      </div>
                    </div>
                  })}
                </>
              }
            </div>
            <div className="text-3xl mt-8 mb-3">สถานที่</div>
            {order.user &&
              <div className="flex items-center gap-x-3 sm:gap-x-5">
                {/* <div className="flex-shrink-0 w-[60px] h-[60px] rounded-full border-2 border-gray"></div> */}
                <div>
                  <div className="sm:text-2xl flex gap-x-2">
                    <span>{`${order.user.firstname} ${order.user.lastname}`}</span>
                    <span className="text-gray">Tel : {order.user.mobileNumber}</span>
                  </div>
                  <div className="sm:text-2xl">
                    {order.user.addressNo}
                  </div>
                </div>
              </div>
            }

          </div>
        </div>
        <div className="flex justify-center mb-[34px]">
          {/* <Link to="/history"> */}
          <div onClick={() => updateRef()} className="btn-green-square flex justify-center items-center px-5 sm:px-0 py-2 text-xl">
            ยืนยันการนัดหมาย
          </div>
          {/* </Link> */}
        </div>
        <div className="hidden sm:block text-center mb-[40px] mx-2">
          <div className="text-xl">
            เมื่อคุณยืนยันการนัดหมายแล้ว ระบบจะทำการบันทึกรายการไว้
            คุณสามารถดูบันทึกรายการทั้งหมดได้ที่ “ประวัติรายการ”
          </div>
          <div className="text-xl">
            อย่าลืมรับโทรศัพท์จากทีมงานนะคะ ,ขอบพระคุณที่ใช้บริการ Areeya Family
            ค่ะ
          </div>
        </div>
      </div>
    </div>
  );
};

export default RepairSummary;
