import { Link } from "react-router-dom"

const HomeComponentMenu = ({ name_th, name_en, link }) => {
    return <>
        <div className="p-5 h-40 rounded-lg bg-green-light flex">
            <div className="flex flex-col justify-between flex-1">
                <div className="flex flex-1 flex-col">
                    <span className="text-2xl font-semibold">{name_th}</span>
                    <span className="text-base">{name_en}</span>
                </div>
                <Link to={`${link}`}>
                    <span className="btn-green w-20 rounded-21">ดำเนินการ</span>
                </Link>
            </div>
        </div>
    </>
}

export default HomeComponentMenu