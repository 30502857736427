import { useEffect, useState } from "react"
import logo from "./logo.svg";
import Footer from "./components/Footer";
import { BrowserRouter as Router, Switch, Route, Link, useLocation } from "react-router-dom";
import Header from "./components/Header";
import ScrollToTop from "./components/ScrollToTop";
import Home from "./pages/home";
import SignIn from "./pages/signin";
import Repair from "./pages/repair";
import Complain from "./pages/complain";
import RepairDetail from "./pages/repair/RepairDetail";
import RepairDetail2 from "./pages/repair/RepairDetail2";
import RepairSummary from "./pages/repair/RepairSummary";
import Suggestion from "./pages/suggestion";
import History from "./pages/history/History";
import RepairStatus from "./pages/history/RepairStatus";
import CancleRepair from "./pages/history/CancleRepair";
import PostPoneRepair from "./pages/history/PostPoneRepair";
import NavbarMobile from "./components/NavbarMobile";
import SideBar from "./components/SideBar";
import RepairUtils from "./pages/repair/Utils";
import RepairDetailUtils from "./pages/repair/RepairDetailUtils";
import CSwlAlert from "./utils/alert";
import ApiCatalog from "./api/ApiCatalog";
import { useSelector } from "react-redux";
import SignInMobile from "./pages/signin/signinMobile";
function App() {
  const [menus, setmenus] = useState([]);
  const userState = useSelector((state) => state.user);
  useEffect(() => {
    if (userState?.userName) {
      CSwlAlert.SwalLoad();
      const promise1 = getCampaign();
      Promise.all([promise1]).then((result) => {
        CSwlAlert.SwalClose();
      })
    }
  }, []);

  const getCampaign = async () => {
    const response = await ApiCatalog.getCampaign();
    if (response.status == 200) {
      const { data } = response.data;
      if (data?.length > 0) {
        const newMenu = [];
        data.map((item, index) => {
          newMenu.push(
            {
              name_th: item.campaignName,
              name_en: "",
              link: `/repair-detail?request=${item.catalogId}`
            }
          )
        })
        setmenus(newMenu);
      }
    }
  }
  const [sidebar, setSidebar] = useState(false)

  return (
    <>
      <Router forceRefresh={true}>
        <div className="App flex flex-col sm:block">
          <div className={`flex-shrink-0 w-[80%] bg-black ${sidebar ? 'w-[80%]' : 'w-0'} duration-300 sm:hidden`}>
            <SideBar menus={menus} sidebar={sidebar} setSidebar={setSidebar} />
          </div>
          <div>
            <Header menus={menus} sidebar={sidebar} setSidebar={setSidebar} />
            <ScrollToTop />
            <Switch>
              <Route exact path="/" component={Home}></Route>
              <Route path="/signin" component={SignIn}></Route>
              <Route
                path="/signinMobile"
                component={SignInMobile}
              ></Route>
              <Route path="/repair" component={Repair}></Route>
              <Route path="/repairUtils" component={RepairUtils}></Route>
              <Route path="/repair-detail" component={RepairDetail}></Route>
              <Route path="/repair-detail-utils" component={RepairDetailUtils}></Route>
              <Route path="/repair-detail-2" component={RepairDetail2}></Route>
              <Route
                path="/repair-detail-summary"
                component={RepairSummary}
              ></Route>
              <Route path="/repair-status/:id" component={RepairStatus}></Route>
              <Route path="/repair-cancel/:id" component={CancleRepair}></Route>
              <Route
                path="/repair-postpone/:id"
                component={PostPoneRepair}
              ></Route>
              <Route path="/complain" component={Complain}></Route>
              <Route path="/suggestion" component={Suggestion}></Route>
              <Route path="/history" component={History}></Route>
            </Switch>
            {/* <Footer /> */}
            <NavbarMobile />
          </div>
        </div>
      </Router>
    </>
  );
}

export default App;
