import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import ApiAuth from "../../api/ApiAuth";
import ApiConfig from "../../api/ApiConfig";
import ApiUser from "../../api/ApiUser";
import CSwlAlert from "../../utils/alert";
import { setLocalStorage } from "../../utils/localStorage";
import Select from 'react-select'
const SignInMobile = () => {
    const navigate = useHistory();
    const dispatch = useDispatch();
    const [projects, setprojects] = useState([]);
    const location = useLocation();

    const [state, setState] = useState({
        username: "",
        email: "",
        zone: "",
        project: new URLSearchParams(location.search).get("projectCode"),
        addressNo: new URLSearchParams(location.search).get("addressNo"),
        phoneNumber: ""
    });

    const login = async (e) => {
        CSwlAlert.SwalClose();
        try {
            const response = await ApiAuth.login(state);
            if (response.status == 200) {
                CSwlAlert.SwalClose();
                // setstate({ ...state, adminLogin: false, userList: [] })
                setLocalStorage("token", response.data.token);
                const responseUser = await ApiUser.getProfile();
                if (responseUser.status == 200) {
                    const responseProject = await ApiConfig.getProjectFloorAndPosition(responseUser.data.users.project);
                    const nowDate = moment(new Date, "DD/MM/YYYY");
                    const warranteedate = moment(new Date(responseUser.data.users.warranteedate), "DD/MM/YYYY");
                    if (warranteedate.isBefore(nowDate, 'date')) {
                        responseUser.data.users.is_verify = false;
                        const confirmed = await CSwlAlert.SwalConfirm("ขออภัยด้วย ระยะเวลาประกันตัวบ้านของท่านสิ้นสุดแล้ว กรุณาติดต่อ Call Center โทร 1797");
                        if (confirmed.isConfirmed) {
                            dispatch({ type: "set", user: responseUser.data.users, token: response.data.token, messageError: "", projectConfig: responseProject.data?.data });
                            setTimeout(() => {
                                navigate.push('/');
                            }, 1000);
                        } else {
                            setLocalStorage("token", "");
                        }
                    } else {
                        responseUser.data.users.is_verify = true;
                        dispatch({ type: "set", user: responseUser.data.users, token: response.data.token, messageError: "", projectConfig: responseProject.data?.data });

                        setTimeout(() => {
                            navigate.push('/');
                        }, 1000);
                    }

                }
                // CSwl.SwalClose();
            } else if (response.status == 404) {
                CSwlAlert.SwalErr({ description: "xxx" })
            }
        } catch (error) {
            const { data } = error.response;
            await CSwlAlert.SwalErr({ description: data.message });
            navigate.push('/login');
        }
    }

    useEffect(() => {
        login();
    }, [state.project]);




    return <div className="flex flex-col sm:flex-row ">
        <div className="sm:flex-1 hidden sm:flex h-32 sm:py-0 sm:h-auto flex bg-green-light"></div>

    </div>
}

export default SignInMobile;