import axios from "axios";
import { Component } from "react";
import { setHeaderAuth } from "../utils";

class ApiUser extends Component {
    static getProfile = async () => {
        await setHeaderAuth();
        const result = await axios({
            url: "User/Me",
            method: "get",
        });
        return result;
    };
}

export default ApiUser;
